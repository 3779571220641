import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Modal } from "@mui/material";
import { IoClose } from "react-icons/io5";
import { Checkbox, Input, message } from "antd";
import { OTPProps } from "antd/es/input/OTP";
import axios from "axios";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Header = () => {
  const [scrolled, setScrolled] = useState(true);
  const [isScrolled, setIsScrolled] = useState(true);

  const [isHover, setIsHover] = useState({
    home: false,
    services: false,
    project: false,
    pages: false,
    shop: false,
    blog: false,
    multiplepage: false,
    onepage: false,
    team: false,
  });
  const handleMouseEnter = (item: any) => {
    setIsHover((prevState) => ({
      ...prevState,
      [item]: true,
    }));
  };

  const handleMouseLeave = (item: any) => {
    setIsHover((prevState) => ({
      ...prevState,
      [item]: false,
    }));
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const handlenavContact = () => {
    navigate("/");
  };

  const [login, setLogin] = useState({
    log: false,
    otpbox: false,
  });

  const [loginData, setloginData] = useState({
    email: "",
    location: "",
  });
  const [otp, setOtp] = useState("");

  const handleOtpChange = (value: string) => {
    setOtp(value);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked); 
    console.log("Checkbox checked:", e.target.checked);
  };


  const handleNavOtp = async () => {
    try {
      if(isChecked){
        if(loginData.email===""||loginData.location===""){
          return message.error("Email and location required!")
        }
        const res = await axios.post(
          "https://api.vnextit.in/api/user/login",
          loginData
        );
        if (res.data.status === "success") {
          message.success("OTP sent to email");
          setLogin({ log: false, otpbox: true });
          setloginData({
            email: "",
            location: "",
          });
        } else {
          message.error("Check email and try again!");
        }
      }else{
        message.error("Accept terms&conditions")
      }
   
    } catch (error) {
      console.log(error);
      message.error("Check email and try again!");
    }
  };



  const handleOtplogin = async () => {
    try {
      if (!otp || isNaN(Number(otp)) || otp.length !== 6) {
        message.error("Please enter a valid 6-digit OTP.");
        return;
      }

      const res = await axios.post(
        "https://api.vnextit.in/api/user/otpVerify",
        { otp: Number(otp) }
      );

      if (res.data.status === "success") {
        message.success("Login success");
        localStorage.setItem("token", res.data.token);
        if (res.data.token) {
          window.location.href = `https://estore.vnextit.in?authToken=${res.data.token}`;
        } else {
          console.log("Token not found!");
        }

        setOtp("");
      } else {
        message.error("Try again!");
      }
    } catch (error) {
      console.log(error);
      message.error("Try again!");
    }
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`lg-hide m-header ${
          isScrolled ? "header scrolled" : "header"
        }`}
      >
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Brand href="/">
            <img
              className="img-fluid header-logo"
              src="/assets/V next Logo-01.png"
            />
          </Navbar.Brand>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => setLogin({ ...login, log: true })}
            className="contact-btn"
          >
            Estore
          </a>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="servicebd-color">
              <Link to={"/"} className="head-nav">
                {" "}
                Home
              </Link>
              {/* <NavDropdown
                className="head-nav"
                title="Products"
                id="collapsible-nav-dropdown"
              >
                <NavDropdown.Item href="">
                Tabs & Laptops
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Cameras 
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Music Systems
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                MP3 Players
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                DVD Players  
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Home Appliances 
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Washing Machines
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Refrigerators
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Cooking Systems
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                Air Conditioners 
                </NavDropdown.Item>
              </NavDropdown> */}
              <Link to={"/our-products"} className="head-nav">
                Products
              </Link>
              <Link to={"/hiring"} className="head-nav">
                Hiring
              </Link>
              <Link to={"/about-us"} className="head-nav">
                About
              </Link>
              <Nav.Link onClick={handlenavContact} className="head-nav py-0">
                Contact us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <section>
        <Container>
          <div className="row">
            <header
              className={`main-header header-two ${
                scrolled ? "fixed-header" : ""
              }`}
            >
              {/*Header-Upper*/}
              <div className="header-upper">
                <div className="container clearfix">
                  <div className="header-inner rel d-flex align-items-center justify-content-between">
                    <div className="nav-outer clearfix mx-0">
                      <nav className="main-menu d-none d-lg-block navbar-expand-lg">
                        <div className="navbar-collapse collapse clearfix">
                          <ul className="navigation clearfix mb-0 px-0">
                            <li
                              className="dropdown"
                              onMouseEnter={() => handleMouseEnter("home")}
                              onMouseLeave={() => handleMouseLeave("home")}
                            >
                              <Link className="home" to="/">
                                Home
                              </Link>
                            </li>
                            {/* <li
                              className="dropdown service-H"
                              onMouseEnter={() => handleMouseEnter("services")}
                              onMouseLeave={() => handleMouseLeave("services")}
                            >
                              <a href="#">Products</a> <span style={{paddingTop:"6px"}}><i className="fi fi-ss-angle-small-down"></i></span>
                              <div className="polygon-Header">
                                <img
                                  src="/assets/Polygon 3.png"
                                  className="img-fluid"
                                />
                              </div>
                              <ul
                                className="header-lg-dropdown head-li p-3"
                                style={{
                                  display: `${
                                    isHover.services ? "block" : "none"
                                  }`,
                                }}
                              >
                                <h3 className="drop-title">Our Products</h3>
                                <div className="d-flex list-li">
                                  <div>
                                    <li>
                                      <a href=""><i className="fi fi-ss-laptop me-1"></i>Tabs & Laptops</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-camera-retro me-1"></i>Cameras</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-mp3-player me-1"></i>Music Systems</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-list-music me-1"></i>MP3 Players</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-album me-1"></i>DVD Players</a>
                                    </li>
                                  </div>
                                  <div>
                                  <li>
                                      <a href=""><i className="fi fi-ss-marketplace-store me-1"></i>Home Appliances </a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-washer me-1"></i>Washing Machines</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-refrigerator me-1"></i>Refrigerators</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-grill-hot-alt me-1"></i>Cooking Systems</a>
                                    </li>
                                    <li>
                                      <a href=""><i className="fi fi-ss-air-conditioner me-1"></i>Air Conditioners </a>
                                    </li>
                                  </div>
                                </div>
                              </ul>
                              <div className="dropdown-btn">
                                <span className="fas fa-chevron-down" />
                              </div>
                            </li> */}
                            <li className="dropdown">
                              <Link to="/our-products">Products </Link>
                            </li>
                            <li className="dropdown">
                              <Link to="/hiring">Hiring</Link>
                            </li>
                            <li className="dropdown">
                              <Link to="/about-us">About</Link>
                            </li>
                          </ul>
                        </div>
                      </nav>
                    </div>
                    <div className="logo-outer">
                      <div className="logo">
                        <Link to="/">
                          <img
                            className="img-fluid header-logo p-1"
                            src="/assets/V next Logo-01.png"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="contact-show">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => setLogin({ ...login, log: true })}
                        className="contact-btn"
                      >
                        Estore
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>

          <div>
            <Modal
              open={login.log}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div>
                  <div className="text-end">
                    <IoClose
                      onClick={() => setLogin({ ...login, log: false })}
                      style={{ cursor: "pointer", fontSize: "20px" }}
                    />
                  </div>
                  <div className="box-login">
                    <h2 className="text-center">Welcome User !</h2>
                    <p className="text-center mb-0">Enter Email Id </p>
                    <p className="text-center">
                      Enter your official Email id to get OTP
                    </p>
                    <label>Official Email ID</label>
                    <br />
                    <input
                      type="text"
                      onChange={(e) =>
                        setloginData({ ...loginData, email: e.target.value })
                      }
                    />
                    <br />
                    <label>Location</label>
                    <br />
                    <input
                      type="text"
                      onChange={(e) =>
                        setloginData({ ...loginData, location: e.target.value })
                      }
                    />
                    <br />
                    <p className="mb-0 pt-2">Terms & Condition</p>
                    <Checkbox onChange={handleCheckboxChange}>I agree to Terms & Condition</Checkbox>
                    <div className="text-center pt-3">
                      <Button variant="contained" onClick={handleNavOtp}>
                        Login
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
          <div>
            <Modal
              open={login.otpbox}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div>
                  <div className="text-end">
                    <IoClose
                      onClick={() => setLogin({ ...login, otpbox: false })}
                      style={{ cursor: "pointer", fontSize: "20px" }}
                    />
                  </div>
                  <div className="box-login">
                    <h2 className="text-center">Welcome User !</h2>
                    <p className="text-center mb-0">OTP Verification</p>
                    <p className="text-center">
                      Enter OTP code sent to your mail
                    </p>
                    <label className="pb-2">Enter your OTP</label>
                    <br />
                    <Input.OTP
                      value={otp}
                      onChange={(value: string) => handleOtpChange(value)}
                    />

                    <div className="text-center pt-3">
                      <Button variant="contained" onClick={handleOtplogin}>
                        Login
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Header;
